<template>
  <div id="menu">
    <div class="second-div">
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="icon"
              @click="handleShowInput"
              @click.stop="dialog = true"
              >mdi-magnify</v-icon
            >
            <v-dialog
              v-model="dialog"
              max-width="320"
              transition="scale-transition"
              overlay-color="grey"
            >
              <v-card>
                <v-text-field
                  append-icon="mdi-arrow-right"
                  type="search"
                  placeholder="Search"
                  hide-details
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  v-if="showInput"
                  class="show"
                  v-model="searchApi"
                />
              </v-card>
            </v-dialog>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div>
            <v-icon @click.stop="drawer = !drawer" color="#004AAD">mdi-view-dashboard</v-icon>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        style="width: 300px"
      >
        <DocumentationSideBar />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
// import func from "vue-editor-bridge";
import DocumentationSideBar from "../reuseables/DocumentationSideBar.vue";
export default {
  name: "MobileSideBar",
  components: {
    DocumentationSideBar,
  },
  data() {
    return {
      drawer: null,
      showInput: false,
      dialog: false,
      items: [],
      searchApi: "",
      Apis: [
        {
          title: "view a transport company",
          link: "https://api.ng.roadpadi.com/companysend",
        },
        {
          title: "buy something from me",
          link: "https://api.ng.roadpadi.com/companysend",
        },
      ],
    };
  },
  methods: {
    handleShowInput() {
      this.showInput = true;
    },
  },
  watch: {
    searchApi: {
      handler: function (val) {
        let apiSet = new Set();
        this.Apis.forEach((api) => {
          if (val && api.title.includes(val)) {
            this.items = [];
            apiSet.add(api);
            this.items = Array.from(apiSet);
          } else {
            this.items = [];
          }
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  padding: 0 10px;
}
#menu {
  display: none;
  .v-text-field {
    @media (max-width: 992px) {
      margin-top: 0 !important;
    }
  }
  @media (max-width: 992px) {
    display: flex;
    .sidebar {
      height: fit-content;
      border: none;
      box-shadow: none;
    }
  }
  .v-navigation-drawer {
    width: 300px;
  }
}

.show {
  color: rgba(29, 38, 45, 0.28);
  outline: none;
  padding: 8px 0;
  background-color: #fdfeff;
  border: 1px solid rgba(230, 232, 233, 0.98);
  box-sizing: border-box;
}

.v-icon {






  cursor: pointer;
  padding: 10px;
}

.v-dialog__content {
  align-items: flex-start !important;
}
::v-deep .v-dialog{
  box-shadow: none !important;
  margin-left: 15px!important;
  margin-right: 15px !important;
  background: #FFFFFF;
}
</style>