<template>
  <div id="documentation">
    <div class="tw-flex tw-row justify-space-between first-div">

        <img
          src="https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png"
          alt="Nomadicpod logo"
          style="cursor: pointer; width: auto; height: 40px"
          class="pt-2 pr-6"
          @click="routeToHome"
        />

      <div class="second-div tw-hidden">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              append-icon="mdi-magnify"
              placeholder="Search"
              rounded
              hide-details
              class="search-bar button-font"
              v-bind="attrs"
              v-on="on"
              v-model="searchApi"
            />
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="third-div">
        <v-btn
            class="white-btn button-font"
            text
            @click="routeToDashboard"
        >Dashboard</v-btn
        >
        <div class="tw-hidden">
        <v-btn
          class="white-btn button-font"
          text
          @click="$router.push({ name: 'Login' })"
          >Login</v-btn
        >
        <v-btn
          class="blue-btn button-font"
          @click="$router.push({ name: 'Register' })"
          >Sign Up</v-btn
        >
        </div>
      </div>
      <DocMobileSideBar />
    </div>
    <div class="tw-flex flex-row imported">
      <DocumentationSideBar />
      <DocumentationHome />
    </div>
  </div>
</template>

<script>
import DocumentationSideBar from "../components/reuseables/DocumentationSideBar.vue";
import DocumentationHome from "../components/reuseables/DocumentationHome.vue";
import DocMobileSideBar from "../components/reuseables/DocMobileSideBar.vue";

export default {
  name: "Documentation",
  components: {
    DocumentationSideBar,
    DocumentationHome,
    DocMobileSideBar,
  },
  data() {
    return {
      items: [],
      searchApi: "",
      Apis: [
        {
          title: "view a transport company",
          link: "https://api.ng.roadpadi.com/companysend",
        },
        {
          title: "buy something from me",
          link: "https://api.ng.roadpadi.com/companysend",
        },
      ],
    };
  },
  watch: {
    searchApi: {
      handler: function (val) {
        let apiSet = new Set();
        this.Apis.forEach((api) => {
          if (val && api.title.includes(val)) {
            this.items = [];
            apiSet.add(api);
            this.items = Array.from(apiSet);
            console.log(this.items)
          } else {
            this.items = [];
          }
        });
      },
      immediate: true,
    },
  },
  methods:{
    routeToDashboard(){
      sessionStorage.setItem("dashboardComponent", "dashboard");
      this.$store.dispatch("dashboardComponent/setCurrentComponent", "dashboard");
      this.$router.push({name:'MainDashboard'})
    },
    routeToHome(){
      if (this.$route.name === "HomePage"){
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,"_self")
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#documentation {
  background-color: #fdfeff !important;
  padding: 3% 15%;
  min-height: 100vh;
  margin: 0 auto;
  @media (max-width: 1400px) {
    padding: 5% 7%;
  }
  @media (max-width: 992px) {
    padding: 0 5% 7%;
  }
  .sidebar,
  .second-div,
  .third-div {
    @media (max-width: 992px) {
      display: none;
    }
  }
}
.first-div {
  padding-bottom: 50px;
  // border: 1px solid rgba(0, 17, 39, 0.06);
  @media (max-width: 992px) {
    padding: 20px;
  }
  @media (max-width: 768px) {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

.button-font {
  font-family: "Inter",sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-transform: initial;
}
.white-btn {
  color: #004aad;
  padding: 20px !important;
  margin-right: 10px;
}
.blue-btn {
  background-color: #004aad !important;
  box-shadow: 0 2px 8px rgba(130, 130, 130, 0.15);
  border-radius: 10px;
  color: #ffffff;
  padding: 20px !important;
}
.second-div {
  border: 1px solid rgba(230, 232, 233, 0.98);
  border-radius: 20px;
  width: 400px;
  .v-text-field {
    padding-top: 3px;
  }
}
</style>