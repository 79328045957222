<template>
  <div class="sidebar">
    <div class="scroll">
      <v-treeview :items="items">
        <template v-slot:label="{ item }">
          <div v-if="item.to" @click="scrollTo(item.name)" class="text-capitalize">
            <router-link
              :to="item.to"
              v-if="item.to"
              @click="scrollTo(item.name)"

              class="text-capitalize">{{ item.name }}</router-link
            >
          </div>
          <span v-else class="text-capitalize">{{ item.name }}</span>
        </template>
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon" style="color: #004aad">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon v-else style="color: #004aad">
            {{ icons[item.icon] }}
          </v-icon>
        </template>
      </v-treeview>
    </div>
<!--    <div class="third-div">-->
<!--      <v-btn-->
<!--        class="white-btn button-font"-->
<!--        text-->
<!--        @click="$router.push({ name: 'Login' })"-->
<!--        >Login</v-btn-->
<!--      >-->
<!--      <v-btn-->
<!--        class="blue-btn button-font"-->
<!--        @click="$router.push({ name: 'Register' })"-->
<!--        >Sign Up</v-btn-->
<!--      >-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "DocumentationSideBar",
  data: () => ({
    icons: {
      booking: "mdi-file-table",
      trip: "mdi-map-marker-distance",
      route: "mdi-directions-fork",
      fleet: "mdi-bus-multiple",
      terminal: "mdi-bus-stop",
      company: "mdi-domain",
      guides: "mdi-routes",
      PR: "mdi-dots-grid",
      FR: "mdi-rhombus-outline",
      home: "mdi-home",
    },

    tree: [],
    items: [
      {
        name: "home",
        icon: "home",
        to: "#home",
      },
      {
        name: "Resources",
        icon: "FR",
        to: "#Resources",

        children: [
          // {
          //   name: "Get all Tsp",
          //   icon: "company",
          //   to: "#Get all Tsp",
          //   children: [
          //     {
          //       name: "Get a Tsp",
          //       icon: "none",
          //       to: "#Get a Tsp",
          //     },
          //     {
          //       name: "Subscribe Tsp",
          //       icon: "none",
          //       to: "#Subscribe Tsp",
          //     },
          //     {
          //       name: "Get all Sub-Tsp",
          //       icon: "none",
          //       to: "#Get all Sub-Tsp",
          //     },
          //     {
          //       name: "Unsubscribe Tsp",
          //       icon: "none",
          //       to: "#Unsubscribe Tsp",
          //     },
          //   ]
          // },

          // {
          //   name: "terminals",
          //   icon: "terminal",
          //   to: "#terminals",
          // },
          // {
          //   name: "Fleets",
          //   icon: "fleet",
          //   to: "#fleets",
          // },
          // {
          //   name: "Routes",
          //   icon: "route",
          //   to: "#route",
          // },
          {
            name: "Get all Trips",
            icon: "trip",
            to: "#Get all Trips",
            children: [{
              name: "Get a Trip",
              icon: "none",
              to: "#Get a Trip",
            },]
          },

          {
            name: "Book a Trip",
            icon: "booking",
            to: "#Book a Trip",
            children:[
              {
                name: "All Bookings",
                icon: "none",
                to: "#All Bookings",
              },
              {
                name: "Get Booking by Ref Id",
                icon: "none",
                to: "#Get Booking by Ref Id",
              },
            ]
          },

        ],
      },
      // {
      //   name: "Paid Resources",
      //   icon: "PR",
      //   to: "#PaidResources",
      //
      //   children: [
      //     {
      //       name: "companiess",
      //       icon: "company",
      //       to: "#Get all Transport Companies",
      //
      //       children: [
      //         {
      //           name: "roadpadi tech",
      //           icon: "",
      //         },
      //       ],
      //     },
      //     {
      //       name: "terminalss",
      //       icon: "terminal",
      //       to: "#terminals",
      //     },
      //     {
      //       name: "Fleets",
      //       icon: "fleet",
      //       to: "#fleets",
      //     },
      //     {
      //       name: "Routes",
      //       icon: "route",
      //       to: "#route",
      //     },
      //     {
      //       name: "Trips",
      //       icon: "trip",
      //       to: "#trips",
      //     },
      //     {
      //       name: "Bookings",
      //       icon: "booking",
      //       to: "#booking",
      //     },
      //   ],
      // },
      {
        name: "Guide",
        icon: "guides",
        to: "#guide",
      },
    ],
  }),
  methods: {
    scrollTo(name) {
      const el = document.getElementById(name);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: auto;
  height: fit-content;
  padding: 30px 0;
  background-color: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px !important;
  @media (max-width: 1264px) {
    padding: 30px 0 30px 20px;
  }
}
.v-application a {
  color: rgba(29, 38, 45, 0.71) !important;
}
.v-treeview-node__label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: rgba(29, 38, 45, 0.71);
}
.v-treeview-node__root {
  .v-treeview-node__children .v-treeview-node__label {
    font-weight: 400;
    font-size: 12px;
  }
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 540px;
  width: 250px;
  height: fit-content;
  @media (max-width: 1264px) {
    width: 100%;
    max-height: 95vh;
  }
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #dadada !important;

  border-radius: 6px;
}
.third-div {
  display: none;
  position: relative;
  padding: 40px 20px;
  @media (max-width: 1264px) {
    display: block;
  }
}
.button-font {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-transform: initial;
}
.white-btn {
  color: #004aad;
  padding: 20px !important;
  margin-right: 10px;
}
.blue-btn {
  background-color: #004aad !important;
  box-shadow: 0px 2px 8px rgba(130, 130, 130, 0.15);
  border-radius: 10px;
  color: #ffffff;
  padding: 20px !important;
}
</style>